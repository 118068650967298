import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './guard/admin.guard';
import { FacultyGuard } from './guard/faculty.guard';
import { StudentGuard } from './guard/student.guard';
import { TestGuard } from './guard/test.guard';

const routes: Routes = [
  { path:'', redirectTo: '/', pathMatch: 'full' },
  { path:'', loadChildren:()=>import('./site/site.module').then(m=>m.SiteModule) },
  { path:'admin', loadChildren:()=>import('./admin/admin.module').then(m=>m.AdminModule),canActivate:[AdminGuard] },
  { path:'faculty', loadChildren:()=>import('./faculty/faculty.module').then(m=>m.FacultyModule),canActivate:[FacultyGuard] },
  { path:'student', loadChildren:()=>import('./student/student.module').then(m=>m.StudentModule),canActivate:[StudentGuard] },
  { path:'test', loadChildren:()=>import('./test/test.module').then(m=>m.TestModule),canActivate:[TestGuard,StudentGuard] },
  { path:'**', redirectTo:'/', pathMatch:'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
